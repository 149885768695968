import { all } from "redux-saga/effects";
import watchGetUserSubsidiaryTokenSaga from "./getUserSubsidiaryTokenSaga";

function* userSubsidiaryTokenRootSaga() {
    yield all([
        watchGetUserSubsidiaryTokenSaga()

    ]);
}

export default userSubsidiaryTokenRootSaga;
