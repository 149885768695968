import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPostAcrSapRequestUpdatePayload {
    acr_no: string;
    system_id: string;
    approverType: string;
    approver_status: string;
    remark: string;
    sap_data: {
        CLIENTID: string;
        SYSTEMID: string;
        ACRNUM: string;
        ACRSTATUS: string;
        BNAME: string;
        VALID_DT: string;
        VALID_TM: string;
        VAL_STATUS: string;
        REMARKS: string;
        BNAME2: string;
        APRID_DT2: string;
        APRID_TM2: string;
        APR2_STATUS: string;
        APR2_REMARKS: string;
        FINAL_STATUS: string;
        VALID_REJ_IND: string;
    }[];
}

export interface IPostAcrSapRequestUpdateData {
    status_code: string;
    message: string;
    data: string;
}

export interface IPostAcrSapRequestUpdateDataState {
    response: IPostAcrSapRequestUpdateData;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: any | null;
}


const initialState: IPostAcrSapRequestUpdateDataState = {
    response: {
        status_code: "",
        message: "",
        data: ""
    },
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null
};

export const postAcrSapRequestUpdateSlice = createSlice({
    name: "postAcrSapRequestUpdateSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        postAcrSapRequestUpdateStart: (state, action: PayloadAction<IPostAcrSapRequestUpdatePayload>) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        postAcrSapRequestUpdateSuccess: (state, action: PayloadAction<IPostAcrSapRequestUpdateData>) => {
            state.response = action.payload;
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
        },
        postAcrSapRequestUpdateFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { postAcrSapRequestUpdateStart, postAcrSapRequestUpdateSuccess, postAcrSapRequestUpdateFailure } = postAcrSapRequestUpdateSlice.actions;

export default postAcrSapRequestUpdateSlice.reducer;