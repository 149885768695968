import { all } from "redux-saga/effects";
import watchTodosSaga from "./todosSaga";
import aspACRRootSaga from "./acrSapSaga";
import userInfoRootSaga from "./userInfoSaga";
import userSubsidiaryTokenRootSaga from "./authSaga";

function* rootSaga() {
    yield all([
        watchTodosSaga(),
        aspACRRootSaga(),
        userInfoRootSaga(),
        userSubsidiaryTokenRootSaga()
    ]);
}

export default rootSaga;
