import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGetAcrSapEmpRowCountPayload {
    empId: string;
    compEntity: string;
}

export interface IGetAcrSapEmpRowCountData {
    status_code: string;
    message: string;
    data: {
        approver: boolean;
        initiator: boolean;
    };
}

export interface IGetAcrSapEmpRowCountDataState {
    response: IGetAcrSapEmpRowCountData;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: any | null;
}


const initialState: IGetAcrSapEmpRowCountDataState = {
    response: {
        status_code: "",
        message: "",
        data: {
            approver: false,
            initiator: false
        }
    },
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null
};

export const getAcrSapEmpRowCountSlice = createSlice({
    name: "getAcrSapEmpRowCountSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getAcrSapEmpRowCountStart: (state, action: PayloadAction<IGetAcrSapEmpRowCountPayload>) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        getAcrSapEmpRowCountSuccess: (state, action: PayloadAction<IGetAcrSapEmpRowCountData>) => {
            state.response = action.payload;
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
        },
        getAcrSapEmpRowCountFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { getAcrSapEmpRowCountStart, getAcrSapEmpRowCountSuccess, getAcrSapEmpRowCountFailure } = getAcrSapEmpRowCountSlice.actions;

export default getAcrSapEmpRowCountSlice.reducer;