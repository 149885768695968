import { put, takeLatest } from "redux-saga/effects";
import { httpPost } from "../../../services";
import { getAcrSapRequestDetailsStart, getAcrSapRequestDetailsSuccess, getAcrSapRequestDetailsFailure, IGetAcrSapRequestDetailsPayload } from "../../slice/acrSapSlice/getAcrSapRequestDetailsSlice";
import { FIN_POWER_BASE_URL, ACR_SAP_CONFIG } from "../../../config";

function* GetAcrSapRequestDetailsSaga(actions: { type: string, payload: IGetAcrSapRequestDetailsPayload; }) {
    try {
        const { data } = yield httpPost(FIN_POWER_BASE_URL + ACR_SAP_CONFIG.GET_ACR_SAP_REQUEST_DETAILS,
            actions.payload,
            {
                headers: {
                    // token: API_SECRET_TOKEN,
                    // "x-teams-origin": window.location.origin
                    // "x-teams-origin": "http://localhost:3000"
                }
            });

        yield put(getAcrSapRequestDetailsSuccess(data));

    } catch (error) {
        console.error("Error fetching GetAcrSapRequestDetailsSaga:", error);
        yield put(getAcrSapRequestDetailsFailure(error));
    }
}

export default function* watchGetAcrSapRequestDetailsSaga() {
    yield takeLatest(getAcrSapRequestDetailsStart, GetAcrSapRequestDetailsSaga);
    // while (true) {
    //     const { payload } = yield take(getAcrSapRequestDetailsStart.type);
    //     yield call(GetAcrSapRequestDetailsSaga, payload);
    // }
}