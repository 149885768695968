import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAppConfig {
    dimension: { deviceWidth: number; deviceHeight: number; };
    path?: string;

}

// Initial state matching the shape of IAppConfig
const initialState: IAppConfig = {
    dimension: { deviceWidth: 0, deviceHeight: 0 },
    path: "",

};

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        deviceDimensions(state, action: PayloadAction<{ deviceWidth: number; deviceHeight: number; }>) {
            state.dimension = action.payload;
        },
        setPath(state, action: PayloadAction<string>) {
            state.path = action.payload;
        },
    },
});

// Export the actions
export const { deviceDimensions,
    setPath,
} = appSlice.actions;

// Export the reducer
export default appSlice.reducer;
