import { put, takeLatest } from "redux-saga/effects";
import { httpPost } from "../../../services";
import { getAcrSapInitiatorListStart, getAcrSapInitiatorListSuccess, getAcrSapInitiatorListFailure, IGetAcrSapInitiatorListPayload } from "../../slice/acrSapSlice/getAcrSapInitiatorListSlice";
import { FIN_POWER_BASE_URL, ACR_SAP_CONFIG } from "../../../config";

function* GetAcrSapInitiatorListSaga(actions: { type: string, payload: IGetAcrSapInitiatorListPayload; }) {
    try {
        const { data } = yield httpPost(FIN_POWER_BASE_URL + ACR_SAP_CONFIG.GET_ACR_SAP_REQUEST_INITIATOR,
            actions.payload,
            {
                headers: {
                    // token: API_SECRET_TOKEN,
                    // "x-teams-origin": window.location.origin
                    // "x-teams-origin": "http://localhost:3000"
                }
            });

        yield put(getAcrSapInitiatorListSuccess(data));

    } catch (error) {
        console.error("Error fetching GetAcrSapInitiatorList:", error);
        yield put(getAcrSapInitiatorListFailure(error));
    }
}

export default function* watchGetAcrSapInitiatorListSaga() {
    yield takeLatest(getAcrSapInitiatorListStart, GetAcrSapInitiatorListSaga);
    // while (true) {
    //     const { payload } = yield take(getAcrSapInitiatorListStart.type);
    //     yield call(GetAcrSapInitiatorListSaga, payload);
    // }
}