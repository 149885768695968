import { put, takeEvery } from "redux-saga/effects";
import { API_GET_TODOS } from "../../config/apiConstant";
import { fetchTodosStart, fetchTodosSuccess, fetchTodosFailure } from "../slice/getTodosSlice";
import { httpGet } from "../../services";

function* fetchTodosSaga() {
    try {
        // yield put(fetchTodosStart());

        const { data } = yield httpGet(API_GET_TODOS);
        yield put(fetchTodosSuccess(data));

    } catch (error) {
        console.error("Error fetching todos:", error);
        yield put(fetchTodosFailure(error));
    }
}

export default function* watchTodosSaga() {
    yield takeEvery(fetchTodosStart.type, fetchTodosSaga);
}