import { call, put, take } from "redux-saga/effects";
import { httpPost } from "../../../services";
import { getAcrSapStatusCountStart, getAcrSapStatusCountSuccess, getAcrSapStatusCountFailure, IGetAcrSapEmpStatusPayload } from "../../slice/acrSapSlice/getAcrSapStatusCountSlice";
import { FIN_POWER_BASE_URL, ACR_SAP_CONFIG } from "../../../config";

function* GetAcrSapStatusCountSaga(payload: IGetAcrSapEmpStatusPayload) {
    try {
        const { data } = yield httpPost(FIN_POWER_BASE_URL + ACR_SAP_CONFIG.GET_ACR_SAP_STATUS_COUNT,
            payload,
            {
                headers: {
                    // token: API_SECRET_TOKEN,
                    // "x-teams-origin": window.location.origin
                    //   "x-teams-origin": "http://localhost:3000"
                }
            });

        yield put(getAcrSapStatusCountSuccess(data));

    } catch (error) {
        console.error("Error fetching GetAcrSapStatusCountSaga:", error);
        yield put(getAcrSapStatusCountFailure(error));
    }
}

export default function* watchGetAcrSapStatusCountSaga() {
    // yield takeEvery(getAcrSapStatusCountStart.type, GetAcrSapStatusCountSaga);
    while (true) {
        const { payload } = yield take(getAcrSapStatusCountStart.type);
        yield call(GetAcrSapStatusCountSaga, payload);
    }
}