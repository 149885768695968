import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoginState {
    username: string | null;
    password: string | null;
  }
  
  const initialState: LoginState = {
    username: null,
    password: null,
  };

  const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
      setLoginDetails: (state, action: PayloadAction<{ username: string; password: string }>) => {
        state.username = action.payload.username;
        state.password = action.payload.password;
      },
    },
  });
  
  export const { setLoginDetails } = loginSlice.actions;
  
  export default loginSlice.reducer;