// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.segmentHeader {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(91, 98, 192, 0.05) 100%);
  padding: 0.5rem 1rem;
  margin: 0;
}

.headerColor {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(91, 98, 192, 0.05) 100%);
}

.segmentBody {
  border-radius: 5px !important;
  padding: 0 !important;
}

.inputBody {
  border: 0.5px solid #e9e9e9 !important;
  border-radius: 3px;
}

.ui-popup__content__content {
  padding: 0px !important;
}

.reactionDialog .ui-menu__itemwrapper {
  width: inherit !important;
}
.reactionDialog .ui-menu__itemwrapper .ui-menu__item {
  width: inherit !important;
}

.box_reactangle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 52px;
  border: 0.5px solid #f0f0f0;
  border-radius: 5px;
}

.loadingText {
  transform: translateX(-50%);
  position: absolute;
  top: 60%;
  left: 52%;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,+FAAA;EAKA,oBAAA;EACA,SAAA;AAHF;;AAMA;EACE,+FAAA;AAHF;;AAUA;EACE,6BAAA;EACA,qBAAA;AAPF;;AAUA;EACE,sCAAA;EACA,kBAAA;AAPF;;AAUA;EACE,uBAAA;AAPF;;AAWE;EACE,yBAAA;AARJ;AAUI;EACE,yBAAA;AARN;;AAaA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,2BAAA;EACA,kBAAA;AAVF;;AAaA;EACE,2BAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;AAVF","sourcesContent":[".segmentHeader {\n  background: linear-gradient(\n    180deg,\n    rgba(255, 255, 255, 0.05) 0%,\n    rgba(91, 98, 192, 0.05) 100%\n  );\n  padding: 0.5rem 1rem;\n  margin: 0;\n}\n\n.headerColor {\n  background: linear-gradient(\n    180deg,\n    rgba(255, 255, 255, 0.05) 0%,\n    rgba(91, 98, 192, 0.05) 100%\n  );\n}\n\n.segmentBody {\n  border-radius: 5px !important;\n  padding: 0 !important;\n}\n\n.inputBody {\n  border: 0.5px solid #e9e9e9 !important;\n  border-radius: 3px;\n}\n\n.ui-popup__content__content {\n  padding: 0px !important;\n}\n\n.reactionDialog {\n  .ui-menu__itemwrapper {\n    width: inherit !important;\n\n    .ui-menu__item {\n      width: inherit !important;\n    }\n  }\n}\n\n.box_reactangle {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 52px;\n  width: 52px;\n  border: 0.5px solid #f0f0f0;\n  border-radius: 5px;\n}\n\n.loadingText {\n  transform: translateX(-50%);\n  position: absolute;\n  top: 60%;\n  left: 52%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
