import { put, takeLatest } from "redux-saga/effects";
import { httpPost } from "../../../services";
import { getStockDataStart, getStockDataSuccess, getStockDataFailure } from "../../slice/acrSapSlice/getStockDataSlice";
import { FIN_POWER_BASE_URL, ACR_SAP_CONFIG } from "../../../config";

function* GetStockDataSaga() {
    try {
        const { data } = yield httpPost(FIN_POWER_BASE_URL + ACR_SAP_CONFIG.GET_STOCK_DATA_API, {},
            {
                headers: {
                    // token: API_SECRET_TOKEN,
                    // "x-teams-origin": window.location.origin
                    // "x-teams-origin": "http://localhost:3000"
                }
            });

        yield put(getStockDataSuccess(data));

    } catch (error) {
        console.error("Error fetching GetAcrSapEmpRowCountSaga:", error);
        yield put(getStockDataFailure(error));
    }
}

export default function* watchGetStockDataSaga() {
    yield takeLatest(getStockDataStart, GetStockDataSaga);
    // while (true) {
    //     const { payload } = yield take(getStockDataStart.type);
    //     yield call(GetStockDataSaga, payload);
    // }
}