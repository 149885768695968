import { combineReducers } from "@reduxjs/toolkit";
import getAcrSapEmpRowCountReducer from "./getAcrSapEmpRowCountSlice";
import getAcrSapApprovalListReducer from "./getAcrSapApprovalListSlice";
import getAcrSapInitiatorListReducer from "./getAcrSapInitiatorListSlice";
import getAcrSapRequestDetailsReducer from "./getAcrSapRequestDetailsSlice";
import postAcrSapRequestUpdateReducer from "./postAcrSapRequestUpdateSlice";
import getAcrSapStatusCountReducer from "./getAcrSapStatusCountSlice";
import getStockDataReducer from "./getStockDataSlice";

const acrSapReducer = combineReducers({
    getAcrSapEmpRowCount: getAcrSapEmpRowCountReducer,
    getAcrSapApprovalList: getAcrSapApprovalListReducer,
    getAcrSapInitiatorList: getAcrSapInitiatorListReducer,
    getAcrSapRequestDetails: getAcrSapRequestDetailsReducer,
    postAcrSapRequestUpdate: postAcrSapRequestUpdateReducer,
    getAcrSapStatusCount: getAcrSapStatusCountReducer,
    getStockData: getStockDataReducer
});

export default acrSapReducer;