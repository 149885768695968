import { put, takeLatest } from "redux-saga/effects";
import { httpPost } from "../../../services";
import { getApprovalListFromAdStart, getApprovalListFromAdSuccess, getApprovalListFromAdFailure, IGetApprovalListFromAdPayload } from "../../slice/userInfoSlice/getApprovalListFromAdSlice";


function* GetGetApprovalListFromAdSaga(actions: {
    type: string;
    payload: IGetApprovalListFromAdPayload;
}) {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${actions.payload.access_token}`,
                "Content-Type": "application/json",
            },
        };
        const { data } = yield httpPost("https://graph.microsoft.com/v1.0/search/query",
            {
                "requests": [
                    {
                        "entityTypes": [
                            "person"
                        ],
                        "query": {
                            "queryString": actions.payload.searchQuery
                        },
                    }
                ]
            },
            config);
        yield put(getApprovalListFromAdSuccess(data));

    } catch (error) {
        console.error("Error fetching GetGetApprovalListFromAdSaga:", error);
        yield put(getApprovalListFromAdFailure(error));
    }
}

export default function* watchGetApprovalListFromAdSaga() {
    yield takeLatest(getApprovalListFromAdStart.type, GetGetApprovalListFromAdSaga);
    // while (true) {
    //     const { payload } = yield take(getApprovalListFromAdStart.type);
    //     yield call(GetGetApprovalListFromAdSaga, payload);
    // }
}