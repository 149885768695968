import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGetAcrSapEmpStatusPayload {
    empId: string;
    compEntity: string;
}

export interface IGetAcrSapStatusCountData {
    status_code: string;
    message: string;
    data: {
        TotalACRCount: number;
        TotalACRApproved: number;
        TotalACRPending: number;
        TotalACRRejected: number;
        TotalNewACR: number;
    };
}

export interface IGetAcrSapStatusCountDataState {
    response?: IGetAcrSapStatusCountData;
    isLoading?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    error?: any | null;
}


const initialState: IGetAcrSapStatusCountDataState = {};

export const getAcrSapStatusCountSlice = createSlice({
    name: "getAcrSapStatusCountSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getAcrSapStatusCountStart: (state, action: PayloadAction<IGetAcrSapEmpStatusPayload>) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        getAcrSapStatusCountSuccess: (state, action: PayloadAction<IGetAcrSapStatusCountData>) => {
            state.response = action.payload;
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
        },
        getAcrSapStatusCountFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { getAcrSapStatusCountStart, getAcrSapStatusCountSuccess, getAcrSapStatusCountFailure } = getAcrSapStatusCountSlice.actions;

export default getAcrSapStatusCountSlice.reducer;