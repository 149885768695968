import { all } from "redux-saga/effects";
import watchGetAcrSapEmpRowCountSaga from "./getAcrSapEmpRowCountSaga";
import watchGetAcrSapApprovalListSaga from "./getAcrSapApprovalListSaga";
import watchGetAcrSapInitiatorListSaga from "./getAcrSapInitiatorListSaga";
import watchGetAcrSapRequestDetailsSaga from "./getAcrSapRequestDetailsSaga";
import watchPostAcrSapRequestUpdateSaga from "./postAcrSapRequestUpdateSaga";
import watchGetAcrSapStatusCountSaga from "./getAcrSapStatusCountSaga";
import watchGetStockDataSaga from "./getStockDataSaga";

function* aspACRRootSaga() {
    yield all([
        watchGetAcrSapEmpRowCountSaga(),
        watchGetAcrSapApprovalListSaga(),
        watchGetAcrSapInitiatorListSaga(),
        watchGetAcrSapRequestDetailsSaga(),
        watchPostAcrSapRequestUpdateSaga(),
        watchGetAcrSapStatusCountSaga(),
        watchGetStockDataSaga()
    ]);
}

export default aspACRRootSaga;
