import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGetUserSubsidiaryTokenPayload {
    empId: any,
    username: string,
    token?: string,
}

export interface IGetUserSubsidiaryTokenData {
    status_code: string;
    message: string;
    istatus_code: string;
    token: string;
    comp_code: string;
}

export interface IGetUserSubsidiaryTokenDataState {
    isLoading?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    error?: any | null;
    response?: IGetUserSubsidiaryTokenData;
}


const initialState: IGetUserSubsidiaryTokenDataState = {};

export const getUserSubsidiaryTokenSlice = createSlice({
    name: "getUserSubsidiaryTokenSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getUserSubsidiaryTokenStart: (state, action: PayloadAction<IGetUserSubsidiaryTokenPayload>) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        getUserSubsidiaryTokenSuccess: (state, action: PayloadAction<IGetUserSubsidiaryTokenData>) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
            state.response = action.payload;
        },
        getUserSubsidiaryTokenFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { getUserSubsidiaryTokenStart, getUserSubsidiaryTokenSuccess, getUserSubsidiaryTokenFailure } = getUserSubsidiaryTokenSlice.actions;

export default getUserSubsidiaryTokenSlice.reducer;