import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGetAcrSapRequestDetailsPayload {
    acr_no: string;
    system_id: string;
}

export interface IGetAcrSapRequestDetailsItem {
    id: number,
    client_id: string,
    system_id: string,
    company_code: string,
    acr_no: string,
    wbs_ele: string,
    wbs_des: string,
    requestor_name: string,
    status: string,
    entry_date: string,
    entry_time: string,
    project: string,
    auc_no: string,
    network: string,
    project_des: string,
    wbc_dec: string,
    auc_balance: string,
    asset_category: string,
    asset_no: string,
    charge_off_cost_center: string,
    asset_class: string,
    sub_number: string,
    cwip_to_au_pending: string;
    pli_usage_modified:string
    gr_details: {
        "@SEGMENT": string,
        WERKS: number,
        EBELN: string,
        TXZ01: string,
        EBELP: number,
        BEDAT: number,
        EFFWR: string,
        MBLNR: string,
        BUZEI: number,
        BUDAT_MKPF: number,
        BLART: string,
        PO_MENGE: string,
        BUALT: string,
        ZAMTTOBECAP: string,
        ZAMTALRDYCAP: string;
        GJAHR: string;
        PENDING: string;
        PSPID: string,
        POSID: string,
        POSID_DIS: string,
        DOCNUM: string,
        CUT_OVER_IND: string,
        BELNR: string,
    }[],

    asset_master_data: {
        "@SEGMENT": string,
        IMPCODE: number,
        KOSTL: number,
        GSBER: string,
        TXT50: string,
        ASTREF: number,
        LIFNR: string,
        MFGYEAR: number,
        MODELA: string,
        SRLNUM: number,
        DTUSE: number,
        DESCR: string,
        MENGE: string,
        MEINS: string,
        ZLOAD: string,
        DEEMD: string,
        WEIGHT: string,
        SHOPLN: number,
        STORT: string,
        KFZKZ: string,
        RAUMN: string,
        PERNR: number,
        RFID: string,
        FIELD1: string,
        FIELD2: string,
        FIELD3: string,
        PLI_INDICATOR: string,
        PLI_REASON: string,
    },
    assetsDetails: {
        "@SEGMENT": string,
        GRAMT: string,
        ASTCAT: string,
        ANLKL: string,
        TXK50: string,
        TXT50: string,
    },
    last_modify_date: string,
    partition_id: string,
    sequence_number: string,
    offset: string,
    acr_type: string,
    notify_list: {
        "@SEGMENT": string,
        PERNR: number,
        BNAME: string,
        NAME_TEXT: string;
    }[];

    approver_1_name: string,
    approver_1_id: string,
    approver_1_status: string,
    approver_1_time: string,
    approver_1_remark: string,
    approver_2_name: string,
    approver_2_id: string,
    approver_2_status: string,
    approver_2_time: string,
    approver_2_remark: string,
    pli_emp_id: string,
    pli_emp_name: string,
    pli_emp_status: string,
    pli_emp_time: string,
    pli_emp_remark: string,
    final_approve_status: string,
    createdDate: string,
    requestor_id: string,
    pli_ind_modified: string ;
}

export interface IGetAcrSapRequestDetailsData {
    status_code: string;
    message: string;
    data: IGetAcrSapRequestDetailsItem;
}

export interface IGetAcrSapRequestDetailsDataState {
    isLoading?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    error?: any | null;
    response?: IGetAcrSapRequestDetailsData;
}


const initialState: IGetAcrSapRequestDetailsDataState = {

};

export const getAcrSapRequestDetailsSlice = createSlice({
    name: "getAcrSapRequestDetailsSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getAcrSapRequestDetailsStart: (state, action: PayloadAction<IGetAcrSapRequestDetailsPayload>) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        getAcrSapRequestDetailsSuccess: (state, action: PayloadAction<IGetAcrSapRequestDetailsData>) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
            state.response = action.payload;
        },
        getAcrSapRequestDetailsFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { getAcrSapRequestDetailsStart, getAcrSapRequestDetailsSuccess, getAcrSapRequestDetailsFailure } = getAcrSapRequestDetailsSlice.actions;

export default getAcrSapRequestDetailsSlice.reducer;