export const SAP_HR_BASE_URL = process.env.REACT_APP_SAP_HR_URL;
export const API_SECRET_TOKEN = process.env.REACT_APP_API_SECRET;
export const FIN_POWER_BASE_URL = process.env.REACT_APP_FIN_POWER_API_URL;

export const USER_TOKEN_API = "/sap-acr-api/get_subsidiary_token/";

export const ACR_SAP_CONFIG = {
    GET_ACR_SAP_APPROVAL_LIST: "/sap-acr-api/get-sap-acr-list",
    GET_ACR_SAP_REQUEST_DETAILS: "/sap-acr-api/get-sap-acr-details",
    GET_ACR_SAP_REQUEST_INITIATOR: "/sap-acr-api/get-sap-acr-initiator-list",
    GET_ACR_SAP_EMP_ROW_COUNT: "/sap-acr-api/count-emp-row",
    GET_ACR_SAP_STATUS_COUNT: "/sap-acr-api/get-sap-acr-status_count",
    POST_ACR_SAP_REQUEST_UPDATE: "/sap-acr-api/update-sap-acr-details",
    GET_STOCK_DATA_API: "/sap-acr-api/get-sap-acr-stock_data"

};

export const USER_INFO_API_CONFIG = {
    GET_USER_MANAGER_INFO: "/authentication/get_user_and_manager_details/",
};