import { call, put, take, } from "redux-saga/effects";
import { httpPost } from "../../../services";
import { getAcrSapEmpRowCountStart, getAcrSapEmpRowCountSuccess, getAcrSapEmpRowCountFailure, IGetAcrSapEmpRowCountPayload } from "../../slice/acrSapSlice/getAcrSapEmpRowCountSlice";
import { FIN_POWER_BASE_URL, ACR_SAP_CONFIG, } from "../../../config";

function* GetAcrSapEmpRowCountSaga(payload: IGetAcrSapEmpRowCountPayload) {
    try {
        const { data } = yield httpPost(FIN_POWER_BASE_URL + ACR_SAP_CONFIG.GET_ACR_SAP_EMP_ROW_COUNT,
            payload,
            {
                headers: {
                    // token: API_SECRET_TOKEN,
                    // "x-teams-origin": window.location.origin
                    // "x-teams-origin": "http://localhost:3000"
                }
            });

        yield put(getAcrSapEmpRowCountSuccess(data));

    } catch (error) {
        console.error("Error fetching GetAcrSapEmpRowCountSaga:", error);
        yield put(getAcrSapEmpRowCountFailure(error));
    }
}

export default function* watchGetAcrSapEmpRowCountSaga() {
    // yield takeEvery(getAcrSapEmpRowCountStart.type, fetchGetAcrSapEmpRowCount);
    while (true) {
        const { payload } = yield take(getAcrSapEmpRowCountStart.type);
        yield call(GetAcrSapEmpRowCountSaga, payload);
    }
}