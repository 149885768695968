import { call, put, take, } from "redux-saga/effects";
import { httpPost } from "../../../services";
import { getUserSubsidiaryTokenStart, getUserSubsidiaryTokenSuccess, getUserSubsidiaryTokenFailure, IGetUserSubsidiaryTokenPayload } from "../../slice/authSlice/getUserSubsidiaryTokenSlice";
import { FIN_POWER_BASE_URL, USER_TOKEN_API, } from "../../../config";

function* GetGetUserSubsidiaryTokenSaga(payload: IGetUserSubsidiaryTokenPayload) {
    try {
        const config = {
            headers: {
                // Authorization: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6InRtbC1kYXNoYm9hcmQiLCJpYXQiOjE2NDkzNTMxNTl9.7c0aYn16h9s3oZg6myjJf0mt17UC27YLyZ4mGir0i2U`,
                // token: API_SECRET_TOKEN,
                // "x-teams-origin": window.location.origin
            },
        };
        const { data } = yield httpPost(
            FIN_POWER_BASE_URL + USER_TOKEN_API,
            {
                empId: payload.empId,
                platform: "tml-dashboard",
                username: payload.username,
            },
            config
        );

        yield put(getUserSubsidiaryTokenSuccess(data));

    } catch (error) {
        console.error("Error fetching GetGetUserSubsidiaryTokenSaga:", error);
        yield put(getUserSubsidiaryTokenFailure(error));
    }
}

export default function* watchGetUserSubsidiaryTokenSaga() {
    // yield takeLatest(getUserSubsidiaryTokenStart, GetGetUserSubsidiaryTokenSaga);
    while (true) {
        const { payload } = yield take(getUserSubsidiaryTokenStart.type);
        yield call(GetGetUserSubsidiaryTokenSaga, payload);
    }
}