import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGetUserInfoFromAdPayload {
    access_token: string,
    upn: string;
}

export interface IGetUserInfoFromAdData {
    employeeId: string;
    mailNickname: string;
    userPrincipalName: string;
    id: string;
    mail: string;
    manager: {
        employeeId: string;
        mailNickname: string;
        userPrincipalName: string;
        id: string;
        mail: string;
        department: string;
        displayName: string;
        givenName: string;
        surname: string;
        jobTitle: string;
        mobilePhone: string;
    };
}

export interface IGetUserInfoFromAdDataState {
    isLoading?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    error?: any | null;
    response?: IGetUserInfoFromAdData;
}


const initialState: IGetUserInfoFromAdDataState = {};

export const getUserInfoFromAdSlice = createSlice({
    name: "getUserInfoFromAdSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getUserInfoFromAdStart: (state, action: PayloadAction<IGetUserInfoFromAdPayload>) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        getUserInfoFromAdSuccess: (state, action: PayloadAction<IGetUserInfoFromAdData>) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
            state.response = action.payload;
        },
        getUserInfoFromAdFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { getUserInfoFromAdStart, getUserInfoFromAdSuccess, getUserInfoFromAdFailure } = getUserInfoFromAdSlice.actions;

export default getUserInfoFromAdSlice.reducer;