import { call, put, take, } from "redux-saga/effects";
import { httpPost1 } from "../../../services";
import { getUserManagerInfoStart, getUserManagerInfoSuccess, getUserManagerInfoFailure, IGetUserManagerInfoPayload } from "../../slice/userInfoSlice/getUserManagerInfoSlice";
import { SAP_HR_BASE_URL, USER_INFO_API_CONFIG } from "../../../config";

function* GetGetUserManagerInfoSaga(payload: IGetUserManagerInfoPayload) {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
            },
        };
        const { data } = yield httpPost1(
            SAP_HR_BASE_URL + USER_INFO_API_CONFIG.GET_USER_MANAGER_INFO,
            { empId: payload.empId },
            config
        );

        yield put(getUserManagerInfoSuccess(data));

    } catch (error) {
        console.error("Error fetching GetGetUserManagerInfoSaga:", error);
        yield put(getUserManagerInfoFailure(error));
    }
}

export default function* watchGetUserManagerInfoSaga() {
    // yield takeLatest(getUserManagerInfoStart, GetGetUserManagerInfoSaga);
    while (true) {
        const { payload } = yield take(getUserManagerInfoStart.type);
        yield call(GetGetUserManagerInfoSaga, payload);
    }
}