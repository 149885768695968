import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGetAcrSapApprovalListPayload {
    empId: string;
    start_index: number;
    page_size: number;
    status: string;
    search: string;
}

export interface IGetAcrSapApprovalListItem {
    acr_amount: string;
    acr_no: string;
    approval_status: string;
    approver_1_id: string;
    approver_1_name: string;
    approver_1_status: string;
    approver_2_id: string;
    approver_2_name: string;
    approver_2_status: string;
    asset_category: string;
    entry_date: string;
    requestor_name: string;
    status: string;
    system_id: string;
    wbs_des: string;
    wbs_ele: string;
    pli_emp_id:string;
    pli_emp_name:string;
    pli_emp_status:string
}

export interface IGetAcrSapApprovalListData {
    status_code: string;
    message: string;
    data: [
        [{ RowCount: number; }],
        IGetAcrSapApprovalListItem[]
    ] | [];
}

export interface IGetAcrSapApprovalListDataState {
    response: IGetAcrSapApprovalListData;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: any | null;
}


const initialState: IGetAcrSapApprovalListDataState = {
    response: {
        status_code: "",
        message: "",
        data: []
    },
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null
};

export const getAcrSapApprovalListSlice = createSlice({
    name: "getAcrSapApprovalListSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getAcrSapApprovalListStart: (state, action: PayloadAction<IGetAcrSapApprovalListPayload>) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        getAcrSapApprovalListSuccess: (state, action: PayloadAction<IGetAcrSapApprovalListData>) => {
            state.response = action.payload;
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
        },
        getAcrSapApprovalListFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { getAcrSapApprovalListStart, getAcrSapApprovalListSuccess, getAcrSapApprovalListFailure } = getAcrSapApprovalListSlice.actions;

export default getAcrSapApprovalListSlice.reducer;