// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DataNotFound .dnf-box {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/DNF/dnf.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,aAAA;EACA,kBAAA;AAAJ","sourcesContent":[".DataNotFound {\n  .dnf-box {\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n    // background: linear-gradient(\n    //   26.21deg,\n    //   #532f91 -28.84%,\n    //   #00b5ff 51.93%,\n    //   rgba(117, 82, 203, 0.94) 51.94%\n    // );\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
