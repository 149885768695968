import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";
import { deviceDimensions } from "../redux/slice/appSlice";


const useWindowDimensions = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      const value = {
        deviceWidth: window.innerWidth,
        deviceHeight: window.innerHeight,
      };
      dispatch(deviceDimensions(value));
    };

    // Set initial dimensions
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);
};

export default useWindowDimensions;
