import { call, put, take } from "redux-saga/effects";
import { httpGet } from "../../../services";
import { getUserInfoFromAdStart, getUserInfoFromAdSuccess, getUserInfoFromAdFailure, IGetUserInfoFromAdPayload } from "../../slice/userInfoSlice/getUserInfoFromAdSlice";


function* GetGetUserInfoFromAdSaga(payload: IGetUserInfoFromAdPayload) {
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${payload.access_token}`,
                "Content-Type": "application/json",
            },
        };
        const { data } = yield httpGet(
            `https://graph.microsoft.com/v1.0/users/${payload.upn}?$expand=manager($levels=1)&$select=EmployeeID,mailNickname,mail,userPrincipalName`,
            config
        );

        yield put(getUserInfoFromAdSuccess(data));

    } catch (error) {
        console.error("Error fetching GetGetUserInfoFromAdSaga:", error);
        yield put(getUserInfoFromAdFailure(error));
    }
}

export default function* watchGetUserInfoFromAdSaga() {
    // yield takeLatest(getUserInfoFromAdStart, GetGetUserInfoFromAdSaga);
    while (true) {
        const { payload } = yield take(getUserInfoFromAdStart.type);
        yield call(GetGetUserInfoFromAdSaga, payload);
    }
}