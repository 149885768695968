import React, { useMemo } from "react";
import { ITeamState } from "../../App";
import { Box, Divider, Flex, Grid, MoreIcon, Popup, Text } from "@fluentui/react-northstar";
import { ITSMIcon, OverviewIcon, SAPIDIcon, SapAcrIcon } from "../../assets/icons";
import "./sidebar.scss";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { setPath } from "../../redux/slice/appSlice";

type Props = {
    QueryData: ITeamState;
    children?: JSX.Element;
};

interface IDrag {
    isActive: boolean;
    dragFrom?: number;
    dragTo?: number;
}

interface IMore {
    isActive: boolean;
    startIndex?: number;
    endIndex?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Sidebar = ({ QueryData, children }: Props) => {
    const navigate = useNavigate();
    const pathName = window.location.pathname;
    const state = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const SidebarData = [
        {
            sequence: 0,
            icon: <OverviewIcon />,
            isActive: true,
            path: "/?app_env=" + process.env.REACT_APP_ENV,
            text: "Overview",
            subMenu: {
                isMenu: false,
                subItems: []
            },
            publishDate: "" //MM/DD/YYYY
        },
        {
            sequence: 1,
            icon: <SapAcrIcon />,
            isActive: true,
            path: state.acrSap.getAcrSapEmpRowCount.isSuccess && state.acrSap.getAcrSapEmpRowCount.response?.status_code === "200" ? (state.acrSap.getAcrSapEmpRowCount.response.data.approver && state.acrSap.getAcrSapEmpRowCount.response.data.initiator ? "/acr" : state.acrSap.getAcrSapEmpRowCount.response.data.approver ? "/acr-approvals" : state.acrSap.getAcrSapEmpRowCount.response.data.initiator ? "/acr-initiator" : "/acr") : "/acr",
            text: "ACR",
            subMenu: {
                isMenu: true,
                subItems: [
                    {
                        isActive: true,
                        path: "/acr-initiator",
                        text: "ACR Requests",
                        icon: <SAPIDIcon />,
                    },
                    {
                        isActive: true,
                        path: "/acr-approvals",
                        text: "ACR Approvals",
                        icon: <ITSMIcon />,
                    },
                    // {
                    //     isActive: false,
                    //     path: "/acr-dashboard",
                    //     text: "ACR Dashboard",
                    //     icon: <ITSMIcon />,
                    // }
                ]
            },
            publishDate: "" //MM/DD/YYYY
        }

    ];
    const [sideData, setSideData] = React.useState<any[]>(SidebarData);
    const [isDrag, setIsDrag] = React.useState<IDrag>({ isActive: false });
    const [isMore, setIsMore] = React.useState<IMore>({ isActive: false, startIndex: 0, endIndex: 5 });

    useWindowDimensions();
    const dimensions = useSelector((state: RootState) => state.app.dimension);
    // Memoize the dimensions value
    const memoizedDimensions = useMemo(() => dimensions, [dimensions]);

    const onDragStart = (id: number, _module: string) => {
        isDrag?.dragFrom !== id && setIsDrag({ ...isDrag, isActive: true, dragFrom: id });
    };

    function swapArrayElement(input: any[], from: number, to: number) {
        let numberOfDeletedElm = 1;
        const elm = input.splice(from, numberOfDeletedElm)[0];
        numberOfDeletedElm = 0;
        input.splice(to, numberOfDeletedElm, elm);
    }
    const onDragDrop = async (_id: number, _module: string) => {
        if ((isDrag.dragFrom as number) !== (isDrag.dragTo as number)) {
            isDrag.dragTo !== undefined &&
                swapArrayElement(
                    sideData,
                    isDrag.dragFrom as number,
                    isDrag.dragTo !== 0 ? (isDrag.dragTo as number) : 1
                );
            // const pinData = sideData.map((item: any) => {
            //     return { seq: item.sequence, name: item.text };
            // });
            setIsDrag({ isActive: false });
            //   await dispatch(
            //     createOrUpdateDataAction(props.QueryData.employeeId as string, pinData)
            //   );
        }
    };

    const onDragOver = (id: number, _module: string) => {
        isDrag?.dragTo !== id && setIsDrag({ ...isDrag, dragTo: id });
    };

    const onDragEnd = (_id: number, _module: string) => {
        setIsDrag({ isActive: false });
    };

    const handleRoute = (path: string, position?: number) => {
        state.app.path !== "" && dispatch(setPath(""));
        navigate(path);
        position && swapArrayElement(sideData, position, 4);
    };


    const onDateComaparison = (publishDate: string) => {
        const today = new Date();
        const afterFifteenDays = new Date(publishDate);
        afterFifteenDays.setDate(afterFifteenDays.getDate() + 15); //Is 15 Days Please Change for how many days show new tag
        if (afterFifteenDays > today) return true;
        else return false;
    };

    const isRedirect = state.app.path !== "" ? true : false;
    React.useEffect(() => {
        if (isRedirect) {
            navigate(state.app.path as string);
        }
    }, [isRedirect]);

    React.useEffect(() => {
        setSideData(SidebarData);
    }, [state.acrSap.getAcrSapEmpRowCount.isSuccess && state.acrSap.getAcrSapEmpRowCount.response.status_code == "200"]);

    return (
        <React.Fragment>
            {
                memoizedDimensions.deviceWidth >= 600 ?
                    <>
                        {
                            pathName === "/" ?
                                <>
                                    <Grid columns="repeat(3,1fr)"  >
                                        {
                                            sideData.filter((item) => item?.isActive).slice(isMore.startIndex, isMore.endIndex).map((({ icon, text, path, subMenu, isActive, publishDate }, indexNo) => {
                                                return (isActive &&
                                                    <Flex key={indexNo}
                                                        className="padding-in-grid"
                                                        draggable={indexNo !== 0}
                                                        onDragStart={(e: any) => {
                                                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                                            e.dataTransfer.effectAllowed;
                                                            e.dataTransfer.effectAllowed = "move";
                                                            onDragStart(indexNo, text);
                                                        }}
                                                        onDrop={(e: any) => {
                                                            e.preventDefault();
                                                            e.dataTransfer.effectAllowed === "move" && onDragDrop(indexNo, text);
                                                        }}
                                                        onDragOver={(e: any) => {
                                                            e.preventDefault();
                                                            onDragOver(indexNo, text);
                                                        }}
                                                        onDragEnd={() => onDragEnd(indexNo, text)}
                                                    >
                                                        {isDrag.isActive && isDrag.dragTo === indexNo && indexNo !== 0 && <Divider vertical color="brand" size={2} />}
                                                        <Popup
                                                            key={indexNo}
                                                            on={subMenu.isMenu ? "click" : "context"}
                                                            content={<Flex column>
                                                                {subMenu.subItems.length > 0 && subMenu.subItems.map((item: any, index: any) => {
                                                                    return (item.isActive &&
                                                                        <Box key={item.text + index} className="sidebar-subMenu" onClick={() => pathName !== item.path.split("?")?.[0] && handleRoute(item.path)}>
                                                                            <Flex fill gap="gap.small" hAlign='start' vAlign='center' padding='padding.medium'>
                                                                                {item.icon}
                                                                                <Text content={item.text} color="white" size="medium" />
                                                                            </Flex>
                                                                        </Box>
                                                                    );
                                                                })}
                                                            </Flex>}
                                                            align='top'
                                                            position='after'
                                                            trigger={
                                                                <Box key={text} className={(pathName === path.split("?")[0] || pathName === path || subMenu.subItems.some((sub: any) => sub["path"] === pathName)) && !isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                                                                    styles={({ theme: { siteVariables } }) => ({
                                                                        background: siteVariables.bodyBackground
                                                                    })}
                                                                    onClick={() => subMenu.isMenu ? null : pathName !== path.split("?")?.[0] && handleRoute(path)}>
                                                                    <Flex className={(publishDate && publishDate !== "" && onDateComaparison(publishDate) ? " new-menu-active" : "")} column hAlign='center' vAlign='center' gap='gap.small' fill padding='padding.medium' >
                                                                        {icon}
                                                                        <Text align='center' style={{ whiteSpace: "break-spaces" }} content={text} size="medium" />
                                                                    </Flex>
                                                                    {publishDate && publishDate !== "" && onDateComaparison(publishDate) && <Box className="new-tag">
                                                                        <Text weight="bold" content={"New"} />
                                                                    </Box>}
                                                                </Box>
                                                            }
                                                        />
                                                    </Flex>
                                                );
                                            }))
                                        }
                                        {sideData.filter((item) => item?.isActive).length > 5 && <Box key="moreBtn" className='sidebar_box padding-in-grid'
                                            styles={({ theme: { siteVariables } }) => ({
                                                background: siteVariables.bodyBackground,
                                            })}
                                            onClick={() => isMore.isActive ? setIsMore({ ...isMore, isActive: false, endIndex: 5 }) : setIsMore({ ...isMore, isActive: true, endIndex: SidebarData.length - 1 })}>
                                            <Flex column fill hAlign='center' vAlign='center' gap='gap.small' padding='padding.medium' >
                                                <MoreIcon styles={({ theme: { siteVariables } }) => ({
                                                    color: siteVariables.colorScheme.brand.foreground,
                                                })} size='largest' />
                                                <Text align='center' style={{ whiteSpace: "break-spaces" }} content={isMore.isActive ? "Less" : "More"} size="medium" />
                                            </Flex>
                                            {sideData.filter((item) => item?.isActive).slice(5, sideData.filter((item) => item?.isActive).length).some(obj => obj.publishDate && obj.publishDate !== "" ? onDateComaparison(obj.publishDate) : false) && !isMore.isActive && <Box className="new-tag">
                                                <Text weight="bold" content={"New"} />
                                            </Box>}
                                        </Box>}
                                    </Grid>
                                </>
                                :
                                <>
                                    <Box className='sidebar-root'>
                                        <Flex column className='sidebar-main'>
                                            {sideData.filter((item) => item?.isActive).slice(0, 5).map(({ icon, text, path, subMenu, isActive, publishDate }, indexNo) => {
                                                return (isActive && <Box key={indexNo}
                                                    draggable={indexNo !== 0}
                                                    onDragStart={(e: any) => {
                                                        e.dataTransfer.effectAllowed = "move";
                                                        onDragStart(indexNo, text);
                                                    }}
                                                    onDrop={(e: any) => {
                                                        e.preventDefault();
                                                        onDragDrop(indexNo, text);
                                                    }}
                                                    onDragOver={(e: any) => {
                                                        e.preventDefault();
                                                        onDragOver(indexNo, text);
                                                    }}
                                                    onDragEnd={() => onDragEnd(indexNo, text)}
                                                >
                                                    {isDrag.isActive && isDrag.dragTo === indexNo && indexNo !== 0 && <Divider color="brand" size={2} />}
                                                    <Popup
                                                        key={indexNo}
                                                        on={subMenu.isMenu ? "hover" : "context"}
                                                        content={<Flex column>
                                                            {subMenu.subItems.length > 0 && subMenu.subItems.map((item: any, index: any) => {
                                                                return (item.isActive &&
                                                                    <Box key={item.text + index} className={pathName === item.path ? "sidebar-subMenu-active sidebar-subMenu" : "sidebar-subMenu"} onClick={() => pathName !== item.path.split("?")?.[0] && handleRoute(item.path)}>
                                                                        <Flex fill gap="gap.small" hAlign='start' vAlign='center' padding='padding.medium'>
                                                                            {item.icon}
                                                                            <Text content={item.text} color="white" size="medium" />
                                                                        </Flex>
                                                                    </Box>
                                                                );
                                                            })}
                                                        </Flex>}
                                                        align='top'
                                                        position='after'
                                                        trigger={
                                                            <Box key={text} className={(pathName === path || subMenu.subItems.some((sub: any) => sub["path"] === pathName)) && !isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                                                                styles={({ theme: { siteVariables } }) => ({
                                                                    background: siteVariables.bodyBackground,
                                                                })}
                                                                onClick={() => subMenu.isMenu ? null : pathName !== path.split("?")?.[0] && handleRoute(path)}>
                                                                <Flex className={(publishDate && publishDate !== "" && onDateComaparison(publishDate) ? " new-menu-active" : "")} column hAlign='center' vAlign='center' gap='gap.small' fill padding='padding.medium' >
                                                                    {icon}
                                                                    <Text align='center' style={{ whiteSpace: "break-spaces" }} content={text} size="medium" />
                                                                </Flex>
                                                                {publishDate && publishDate !== "" && onDateComaparison(publishDate) && <Box className="new-tag">
                                                                    <Text weight="bold" content={"New"} />
                                                                </Box>}
                                                            </Box>
                                                        }
                                                    />
                                                    {!(isDrag.isActive && isDrag.dragTo === indexNo && indexNo !== 0) && <Divider fitted />}
                                                </Box>
                                                );
                                            })}
                                            {sideData.filter((item) => item?.isActive).length > 5 && <Popup
                                                key="ismore"
                                                on="hover"
                                                content={
                                                    <Flex gap="gap.small" padding='padding.medium' wrap styles={{ maxWidth: "335px" }}>
                                                        {sideData.filter((item) => item?.isActive).slice(5, SidebarData.length).map(({ icon, text, path, subMenu, isActive, publishDate }, indexNo) => {
                                                            return (isActive && <Box key={indexNo + 5}
                                                                draggable
                                                                onDragStart={(e: any) => {
                                                                    e.dataTransfer.effectAllowed = "move";
                                                                    onDragStart(indexNo + 5, text);
                                                                }}
                                                                onDrop={(e: any) => {
                                                                    e.preventDefault();
                                                                    onDragDrop(indexNo + 5, text);
                                                                }}
                                                                onDragOver={(e: any) => {
                                                                    e.preventDefault();
                                                                    onDragOver(indexNo + 5, text);
                                                                }}
                                                                onDragEnd={() => onDragEnd(indexNo, text)}
                                                            >
                                                                <Popup
                                                                    key={indexNo}
                                                                    on={subMenu.isMenu ? "hover" : "context"}
                                                                    content={<Flex column>
                                                                        {subMenu.subItems.length > 0 && subMenu.subItems.map((item: any, index: any) => {
                                                                            return (item.isActive &&
                                                                                <Box key={item.text + index} className={pathName === item.path ? "sidebar-subMenu-active sidebar-subMenu" : "sidebar-subMenu"} onClick={() => pathName !== item.path.split("?")?.[0] && handleRoute(item.path)}>
                                                                                    <Flex fill gap="gap.small" hAlign='start' vAlign='center' padding='padding.medium'>
                                                                                        {item.icon}
                                                                                        <Text content={item.text} color="white" size="medium" />
                                                                                    </Flex>
                                                                                </Box>
                                                                            );
                                                                        })}
                                                                    </Flex>}
                                                                    align='top'
                                                                    position='after'
                                                                    trigger={
                                                                        <Box
                                                                            key={text} className={(pathName === path || subMenu.subItems.some((sub: any) => sub["path"] === pathName)) && !isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                                                                            styles={({ theme: { siteVariables } }) => ({
                                                                                background: siteVariables.bodyBackground,
                                                                            })}
                                                                            onClick={() => subMenu.isMenu ? null : pathName !== path.split("?")?.[0] && handleRoute(path, indexNo + 5)}>
                                                                            <Flex className={(publishDate && publishDate !== "" && onDateComaparison(publishDate) ? " new-menu-active" : "")} column hAlign='center' vAlign='center' gap='gap.small' fill padding='padding.medium' >
                                                                                {icon}
                                                                                <Text align='center' style={{ whiteSpace: "break-spaces" }} content={text} size="medium" />
                                                                            </Flex>
                                                                            {publishDate && publishDate !== "" && onDateComaparison(publishDate) && <Box className="new-tag">
                                                                                <Text weight="bold" content={"New"} />
                                                                            </Box>}
                                                                        </Box>
                                                                    }
                                                                />
                                                            </Box>
                                                            );
                                                        })}
                                                    </Flex>}
                                                align='top'
                                                position='after'
                                                pointing
                                                trigger={
                                                    <Box key="moreBtn" className={isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                                                        styles={({ theme: { siteVariables } }) => ({
                                                            background: siteVariables.bodyBackground,
                                                        })}
                                                    // onClick={() => setIsMore({ ...isMore, isActive: !isMore.isActive })}
                                                    >
                                                        <Flex column fill hAlign='center' vAlign='center' gap='gap.small' padding='padding.medium' >
                                                            <MoreIcon styles={({ theme: { siteVariables } }) => ({
                                                                color: siteVariables.colorScheme.brand.foreground,
                                                            })} size='largest' />
                                                            <Text align='center' style={{ whiteSpace: "break-spaces" }} content={isMore.isActive ? "Less" : "More"} size="medium" />
                                                        </Flex>
                                                        {sideData.filter((item) => item?.isActive).slice(5, SidebarData.length).some(obj => obj.publishDate && obj.publishDate !== "" ? onDateComaparison(obj.publishDate) : false) && !isMore.isActive && <Box className="new-tag">
                                                            <Text weight="bold" content={"New"} />
                                                        </Box>}
                                                    </Box>
                                                }
                                            />}
                                        </Flex>
                                        <Box className='content' styles={({ theme: { siteVariables } }) => ({
                                            background: siteVariables.colorScheme.grey.backgroundActive,
                                        })} >
                                            {children}
                                        </Box>
                                    </Box>
                                </>
                        }

                    </>
                    :
                    <>
                        <Box className='sidebar-mobile'>
                            {pathName !== "/" && <Box className='content' styles={({ theme: { siteVariables } }) => ({
                                background: siteVariables.colorScheme.grey.backgroundActive,
                            })}>
                                {children}
                            </Box>}

                            <Box
                                styles={({ theme: { siteVariables } }) => ({
                                    background: siteVariables.bodyBackground,
                                    borderRadius: "20px"
                                })}
                            >
                                {isMore.isActive && <Grid columns="repeat(5,1fr)"
                                    styles={{
                                        maxHeight: "200px",
                                        borderTop: "2px solid #bdb9b7",
                                        borderRadius: "20px",
                                        padding: "0.5rem 1rem",
                                        overflow: "auto",
                                        gridGap: "8px"
                                    }} >
                                    {sideData.filter((item) => item?.isActive).slice(4, SidebarData.length).map(({ icon, text, path, subMenu, isActive, publishDate }, indexNo) => {
                                        return (isActive && <Box styles={{ width: "inherit" }}
                                            key={indexNo + 5}
                                            draggable
                                            onDragStart={(e: any) => {
                                                e.dataTransfer.effectAllowed = "move";
                                                onDragStart(indexNo + 5, text);
                                            }}
                                            onDrop={(e: any) => {
                                                e.preventDefault();
                                                onDragDrop(indexNo + 5, text);
                                            }}
                                            onDragOver={(e: any) => {
                                                e.preventDefault();
                                                onDragOver(indexNo + 5, text);
                                            }}
                                            onDragEnd={() => onDragEnd(indexNo, text)}
                                        >
                                            <Popup
                                                key={indexNo}
                                                on={subMenu.isMenu ? "hover" : "context"}
                                                content={<Flex column>
                                                    {subMenu.subItems.length > 0 && subMenu.subItems.map((item: any, index: any) => {
                                                        return (item.isActive &&
                                                            <Box key={item.text + index} className={pathName === item.path ? "sidebar-subMenu-active sidebar-subMenu" : "sidebar-subMenu"} onClick={() => handleRoute(item.path)}>
                                                                <Flex fill gap="gap.small" hAlign='start' vAlign='center' padding='padding.medium'>
                                                                    {item.icon}
                                                                    <Text content={item.text} color="white" size="medium" />
                                                                </Flex>
                                                            </Box>
                                                        );
                                                    })}
                                                </Flex>}
                                                align='top'
                                                position='after'
                                                trigger={
                                                    <Box key={text} className={(pathName === path || subMenu.subItems.some((sub: any) => sub["path"] === pathName)) && !isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                                                        styles={({ theme: { siteVariables } }) => ({
                                                            background: siteVariables.bodyBackground,
                                                        })}
                                                        onClick={() => subMenu.isMenu ? null : handleRoute(path)}>
                                                        <Flex fill className={(publishDate && publishDate !== "" && onDateComaparison(publishDate) ? " new-menu-active" : "")} column hAlign='center' vAlign='center' gap='gap.small' padding='padding.medium' >
                                                            {icon}
                                                            <Text align='center' style={{ whiteSpace: "break-spaces" }} content={text} size="medium" />
                                                        </Flex>
                                                        {publishDate && publishDate !== "" && onDateComaparison(publishDate) && <Box className="new-tag">
                                                            <Text weight="bold" content={"New"} />
                                                        </Box>}
                                                    </Box>
                                                }
                                            />
                                        </Box>
                                        );
                                    })}
                                </Grid>}
                                <Divider fitted />
                                <Flex className='sidebar-main' fill>
                                    {sideData.filter((item) => item?.isActive).slice(0, 4).map(({ icon, text, path, subMenu, isActive, publishDate }, indexNo) => {
                                        return (isActive && <Box key={indexNo}
                                            draggable
                                            onDragStart={(e: any) => {
                                                e.dataTransfer.effectAllowed = "move";
                                                onDragStart(indexNo, text);
                                            }}
                                            onDrop={(e: any) => {
                                                e.preventDefault();
                                                onDragDrop(indexNo, text);
                                            }}
                                            onDragOver={(e: any) => {
                                                e.preventDefault();
                                                onDragOver(indexNo, text);
                                            }}
                                            onDragEnd={() => onDragEnd(indexNo, text)}
                                            style={{ width: "100%" }}
                                        >
                                            <Popup
                                                key={indexNo}
                                                on={subMenu.isMenu ? "hover" : "context"}
                                                content={<Flex column>
                                                    {subMenu.subItems.length > 0 && subMenu.subItems.map((item: any, index: any) => {
                                                        return (item.isActive &&
                                                            <Box key={item.text + index} className={pathName === item.path ? "sidebar-subMenu-active sidebar-subMenu" : "sidebar-subMenu"} onClick={() => handleRoute(item.path)}>
                                                                <Flex fill gap="gap.small" hAlign='start' vAlign='center' padding='padding.medium'>
                                                                    {item.icon}
                                                                    <Text content={item.text} color="white" size="medium" />
                                                                </Flex>
                                                            </Box>
                                                        );
                                                    })}
                                                </Flex>}
                                                align='top'
                                                position='after'
                                                trigger={
                                                    <Box draggable key={text} className={(pathName === path || subMenu.subItems.some((sub: any) => sub["path"] === pathName)) && !isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                                                        styles={({ theme: { siteVariables } }) => ({
                                                            background: siteVariables.bodyBackground,
                                                        })}
                                                        onClick={() => subMenu.isMenu ? null : handleRoute(path)}>
                                                        <Flex className={(publishDate && publishDate !== "" && onDateComaparison(publishDate) ? " new-menu-active" : "")} fill column hAlign='center' vAlign='center' gap='gap.small' padding='padding.medium' >
                                                            {icon}
                                                            <Text align='center' style={{ whiteSpace: "break-spaces" }} content={text} size="medium" />
                                                        </Flex>
                                                        {publishDate && publishDate !== "" && onDateComaparison(publishDate) && <Box className="new-tag">
                                                            <Text weight="bold" content={"New"} />
                                                        </Box>}
                                                    </Box>
                                                }
                                            />
                                        </Box>);
                                    })}
                                    {SidebarData.filter((item) => item.isActive).length > 4 && <Box key="moreBtn" className={isMore.isActive ? "sidebar_box active" : "sidebar_box"}
                                        styles={({ theme: { siteVariables } }) => ({
                                            background: siteVariables.bodyBackground,
                                        })}
                                        onClick={() => setIsMore({ ...isMore, isActive: !isMore.isActive })}>
                                        <Flex column hAlign='center' vAlign='center' gap='gap.small' padding='padding.medium' >
                                            <MoreIcon styles={({ theme: { siteVariables } }) => ({
                                                color: siteVariables.colorScheme.brand.foreground,
                                            })} />
                                            <Text align='center' style={{ whiteSpace: "break-spaces" }} content="More" size="medium" />
                                        </Flex>
                                        {sideData.filter((item) => item?.isActive).slice(4, SidebarData.length).some(obj => obj.publishDate && obj.publishDate !== "" ? onDateComaparison(obj.publishDate) : false) && !isMore.isActive && <Box className="new-tag">
                                            <Text weight="bold" content={"New"} />
                                        </Box>}
                                    </Box>}
                                </Flex>
                            </Box>

                        </Box>
                    </>
            }
        </React.Fragment>
    );
};

export default Sidebar;