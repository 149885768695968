import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGetAcrSapInitiatorListPayload {
    empId: string;
    start_index: number;
    page_size: number;
    status: string;
    search: string;
    compEntity: string;
}

export interface IGetAcrSapInitiatorListItem {
    acr_amount: string;
    acr_no: string;
    approval_status: string;
    approver_1_id: string;
    approver_1_name: string;
    approver_1_status: string;
    approver_2_id: string;
    approver_2_name: string;
    approver_2_status: string;
    asset_category: string;
    entry_date: string;
    requestor_name: string;
    status: string;
    system_id: string;
    wbs_des: string;
    wbs_ele: string;
    pli_emp_id:string;
    pli_emp_name:string;
    pli_emp_status:string
}

export interface IGetAcrSapInitiatorListData {
    status_code: string;
    message: string;
    data: [
        [{ RowCount: number; }],
        IGetAcrSapInitiatorListItem[]
    ];
}

export interface IGetAcrSapInitiatorListDataState {
    response?: IGetAcrSapInitiatorListData;
    isLoading?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    error?: any | null;
}


const initialState: IGetAcrSapInitiatorListDataState = {
  
};

export const getAcrSapInitiatorListSlice = createSlice({
    name: "getAcrSapInitiatorListSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getAcrSapInitiatorListStart: (state, action: PayloadAction<IGetAcrSapInitiatorListPayload>) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        getAcrSapInitiatorListSuccess: (state, action: PayloadAction<IGetAcrSapInitiatorListData>) => {
            state.response = action.payload;
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
        },
        getAcrSapInitiatorListFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { getAcrSapInitiatorListStart, getAcrSapInitiatorListSuccess, getAcrSapInitiatorListFailure } = getAcrSapInitiatorListSlice.actions;

export default getAcrSapInitiatorListSlice.reducer;