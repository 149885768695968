import { combineReducers } from "@reduxjs/toolkit";
import loginReducer from "./loginSlice";
import todosReducer from "./getTodosSlice";
import appReducer from "./appSlice";
import acrSapReducer from "./acrSapSlice";
import userInfoReducer from "./userInfoSlice";
import userSubsidiaryTokenReducer from "./authSlice";

const rootReducer = combineReducers({
    app: appReducer,
    login: loginReducer,
    todos: todosReducer,
    acrSap: acrSapReducer,
    userInfo: userInfoReducer,
    userAuth: userSubsidiaryTokenReducer
});

export default rootReducer;