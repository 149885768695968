import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Todo {
    userId: number;
    id: number;
    title: string;
    completed: boolean;
}

interface TodosState {
    todos: Todo[];
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: any | null;
}

const initialState: TodosState = {
    todos: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null
};

export const todosSlice = createSlice({
    name: "todos",
    initialState,
    reducers: {
        fetchTodosStart: (state) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        fetchTodosSuccess: (state, action: PayloadAction<Todo[]>) => {
            state.todos = action.payload;
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
        },
        fetchTodosFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { fetchTodosStart, fetchTodosSuccess, fetchTodosFailure } = todosSlice.actions;

export default todosSlice.reducer;