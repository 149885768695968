import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGetUserManagerInfoPayload {
    access_token: string,
    empId: string;
}

export interface IGetUserManagerInfoData {
    status_code: string;
    message: string;
    user_details: any[];
    manager_details: any[];
    is_field_level_employee: boolean;
    is_employee_reporting: boolean;
}

export interface IGetUserManagerInfoDataState {
    isLoading?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    error?: any | null;
    response?: IGetUserManagerInfoData;
}


const initialState: IGetUserManagerInfoDataState = {};

export const getUserManagerInfoSlice = createSlice({
    name: "getUserManagerInfoSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getUserManagerInfoStart: (state, action: PayloadAction<IGetUserManagerInfoPayload>) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        getUserManagerInfoSuccess: (state, action: PayloadAction<IGetUserManagerInfoData>) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
            state.response = action.payload;
        },
        getUserManagerInfoFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { getUserManagerInfoStart, getUserManagerInfoSuccess, getUserManagerInfoFailure } = getUserManagerInfoSlice.actions;

export default getUserManagerInfoSlice.reducer;