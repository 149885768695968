import React, { Suspense, useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LazyFallBack from "../components/LazyFallBack";
import { ITeamState } from "../App";
import Sidebar from "../components/Sidebar";
import LoaderComp from "../components/LoaderComp";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Segment } from "@fluentui/react-northstar";

const Overview = React.lazy(() => import("../pages/Overview"));
const ACRApprovals = React.lazy(() => import("../pages/ACRApprovals"));
const ACRRequestDetails = React.lazy(() => import("../components/ACRRequestDetails"));

type Props = { QueryData: ITeamState; };

const RouteList = ({ QueryData }: Props) => {
    useWindowDimensions();
    const dimensions = useSelector((state: RootState) => state.app.dimension);
    // Memoize the dimensions value
    const memoizedDimensions = useMemo(() => dimensions, [dimensions]);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/" element={
                        <Suspense fallback={<LoaderComp />}>
                            <Overview QueryData={QueryData} memoizedDimensions={memoizedDimensions} />
                        </Suspense>
                    }
                />
                <Route
                    path="/acr-initiator"
                    element={
                        <Suspense fallback={<LazyFallBack />}>
                            <Sidebar QueryData={QueryData} >
                                <ACRApprovals QueryData={QueryData} initiator={true} memoizedDimensions={memoizedDimensions} />
                            </Sidebar>
                        </Suspense>
                    }
                />
                <Route
                    path="/acr-approvals" element={
                        <Suspense fallback={<LoaderComp />}>
                            <Sidebar QueryData={QueryData}>
                                <ACRApprovals QueryData={QueryData} memoizedDimensions={memoizedDimensions} />
                            </Sidebar>
                        </Suspense>
                    }
                />
                <Route
                    path="/acr-details/:acrNo/:sysId"
                    element={
                        <Suspense fallback={<LazyFallBack />}>
                            <Sidebar QueryData={QueryData} >
                                <Segment styles={{ margin: 0, padding: 0, borderRadius: "5px !important" }}>
                                    <ACRRequestDetails QueryData={QueryData} memoizedDimensions={memoizedDimensions} />
                                </Segment>
                            </Sidebar>
                        </Suspense>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default RouteList;