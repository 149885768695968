import { all } from "redux-saga/effects";
import watchGetUserInfoFromAdSaga from "./getUserInfoFromAdSaga";
import watchGetUserManagerInfoSaga from "./getUserManagerInfoSaga";
import watchGetApprovalListFromAdSaga from "./getApprovalListFromAdSaga";

function* userInfoRootSaga() {
    yield all([
        watchGetUserInfoFromAdSaga(),
        watchGetUserManagerInfoSaga(),
        watchGetApprovalListFromAdSaga()

    ]);
}

export default userInfoRootSaga;
