import moment from "moment";

export enum EmpValueByCode {
    "0100" = "cv",
    "0550" = "pv",
    "0650" = "ev",
    "0300" = "tmlbsl"
}

export enum PLIAssetType {
    "A" = "New Asset",
    "S" = "Second Hand Asset",
    "R" = "Refurbished Asset"
}
export function getIntegerNoToString(value: string) {
    return parseInt(value).toString();
}

export function getDDMMYYYFormat(date: any) {
    return moment(date).format("DD-MM-YYYY");
}

export function getCommaSeparatorNumber(value: string) {
    return Number.isNaN(parseInt(value)) ? "" : Number(parseFloat(value).toFixed(2)).toLocaleString("en", {
        minimumFractionDigits: 2
    });
}


export function getAddTwoDecimalNumber(value: string) {
    return parseFloat(value).toFixed(2);
}

export function getValueZeroToConversion(initialValue: string) {
    if (/([a-zA-Z/\\*])\w/g.test(initialValue)) {
        return initialValue;
    }
    return Number.isNaN(parseFloat(initialValue)) && !Number.isSafeInteger(initialValue) ? initialValue : parseFloat(initialValue).toString();
}

export const isGreaterThanOneCrore = (num: number): boolean => {
    return num > 10000000;
};
export enum TraceEvent {
    SSO_SUCCESS = "sso finpower login",
    SSO_SUCCESS_ISSUE = "sso finpower issue",
    TML_KEY_CLOCK = "finpower key-clock get",
    TML_KEY_CLOCK_ISSUE = "finpower key-clock issue",
    TML_GRAPH_DATA = "finpower token exchange",
    TML_GRAPH_DATA_ISSUE = "finpower token exchange issue",
    TML_TASK_MODULE = "finpower task module",
    API_ISSUE = "API response error",

    ACR_SAP_REQ_APPROVE = "ACR Approved",
    ACR_SAP_REQ_REJECT = "ACR Rejected",
    ACR_SAP_REQ_APPROVE_ERROR = "ACR Approved Error",
    ACR_SAP_REQ_REJECT_ERROR = "ACR Rejected Error",

}
