import { combineReducers } from "@reduxjs/toolkit";
import getUserInfoFromAdReducer from "./getUserInfoFromAdSlice";
import getUserManagerInfoReducer from "./getUserManagerInfoSlice";
import getApprovalListFromAdReducer from "./getApprovalListFromAdSlice";

const userInfoReducer = combineReducers({
    getUserInfoFromAd: getUserInfoFromAdReducer,
    getUserManagerInfo: getUserManagerInfoReducer,
    getApprovalListFromAd: getApprovalListFromAdReducer
});

export default userInfoReducer;