import React from "react";
import axios from "axios";
import { app as microsoftTeams } from "@microsoft/teams-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Provider, teamsTheme, Dialog, Text, Button, Flex, teamsDarkTheme, ThemePrepared } from "@fluentui/react-northstar";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./redux/store";
import { getAcrSapEmpRowCountStart } from "./redux/slice/acrSapSlice/getAcrSapEmpRowCountSlice";
import { getUserSubsidiaryTokenStart } from "./redux/slice/authSlice/getUserSubsidiaryTokenSlice";
import { getUserManagerInfoStart } from "./redux/slice/userInfoSlice/getUserManagerInfoSlice";
import { API_SECRET_TOKEN, USER_TOKEN_API, FIN_POWER_BASE_URL } from "./config";
import RouteList from "./routes";
import DataNotFound from "./components/DNF";
import ErrorComponent from "./components/ErrorComponent";
import LoaderComp from "./components/LoaderComp";
import MSTeamsWrapper from "./components/MSTeamsWrapper";
import { EmpValueByCode } from "./constant";
import { getStockDataStart } from "./redux/slice/acrSapSlice/getStockDataSlice";

toast.configure({
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  theme: "colored",
});

export interface ITeamState {
  isOnTeams: boolean | null;
  activeTheme: { type: string; theme: ThemePrepared };
  employeeId?: string | null;
  userId?: string | null;
  compEntity: string;
  azureAdToken?: string;
}

function App() {
  const dispatch: AppDispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const [teamState, setTeamState] = React.useState<ITeamState>({
    isOnTeams: null,
    activeTheme: { type: "default", theme: teamsTheme },
    employeeId: "",
    userId: "",
    compEntity: ""
  });
  const [idleTime, setIdleTime] = React.useState<{ isActive: boolean; isLoading: boolean; isError: boolean; }>({ isActive: false, isLoading: false, isError: false });

  const checkInTeams = (): boolean => {
    // eslint-disable-next-line dot-notation
    const microsoftTeamsLib =
      microsoftTeams || (window as any)["microsoftTeams"];

    if (!microsoftTeamsLib) {
      return false; // the Microsoft Teams library is for some reason not loaded
    }

    if (
      (window.parent === window.self && (window as any).nativeInterface) ||
      window.name === "embedded-page-container" ||
      window.name === "extension-tab-frame"
    ) {
      return true;
    }
    return false;
  };

  async function refreshToken() {
    setIdleTime({ ...idleTime, isLoading: true });
    const NewPayload = {
      empId: teamState.employeeId,
      platform: "tml-dashboard",
      username: teamState.userId,
    };
    try {
      const response = await axios.post(FIN_POWER_BASE_URL + USER_TOKEN_API, NewPayload, {
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': `${payload.token}`, // Uncomment and use if needed
          "token": API_SECRET_TOKEN,
          "x-teams-origin": window.location.origin,
        }
      });

      if (response.statusText === "OK") {
        setIdleTime({ ...idleTime, isActive: false, isLoading: false, isError: false });
      } else {
        setIdleTime({ ...idleTime, isLoading: false, isError: true });
      }
    } catch (error) {
      console.error("refreshToken api error", error);
    }
  }

  const inactivityTime = function () {
    let time: any;
    window.addEventListener("load", resetTimer, true);
    const events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];
    events.forEach(function (name) {
      document.addEventListener(name, resetTimer, true);
    });
    function idleCall() {
      if (!idleTime.isActive) setIdleTime({ ...idleTime, isActive: true });
    }
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(idleCall, 1000 * 60 * 15);
      // time = setTimeout(idleCall, 10000);
    }
  };
  if (state.userInfo.getUserManagerInfo.isSuccess) inactivityTime();

  React.useEffect(() => {
    const teams = checkInTeams();
    setTeamState((prev) => ({ ...prev, isOnTeams: teams }));
  }, [teamState.isOnTeams]);


  const getQueryVariable = (variable: string): string | null => {
    const query = window && window.location.search.substring(1);
    const vars = query.split("&");
    for (const varPairs of vars) {
      const pair = varPairs.split("=");
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return null;
  };

  React.useEffect(() => {
    // if (teamState.isOnTeams === false) {
    const empId = getQueryVariable("employeeId");
    const userId = getQueryVariable("userid");
    const compEntity = getQueryVariable("compEntity") || "";
    if (empId !== null && userId !== null && teamState.employeeId === "" && teamState.userId === "") {
      setTeamState((prev) => ({ ...prev, employeeId: empId, userId: userId, compEntity: compEntity as string }));
    }
    // }
  }, [teamState]);

  //Api Call When App as Tab App -> Subsidiary Token 
  React.useEffect(() => {
    if (state.userInfo.getUserInfoFromAd.isSuccess && teamState.isOnTeams === true && teamState.employeeId === "" && teamState.userId === "") {
      // console.log("On Teams - Subsidiary");
      const userID = String(state.userInfo.getUserInfoFromAd.response?.userPrincipalName || "").split("@");
      setTeamState((prev) => ({ ...prev, employeeId: "00" + state.userInfo.getUserInfoFromAd.response?.employeeId, userId: userID[0] }));
      dispatch(getUserSubsidiaryTokenStart({ empId: "00" + state.userInfo.getUserInfoFromAd.response?.employeeId, username: userID[0] }));
    }
  }, [state.userInfo.getUserInfoFromAd.isSuccess]);

  //Api Call When App as Browser App -> Subsidiary Token 
  React.useEffect(() => {
    if (teamState.employeeId !== "" && teamState.userId !== "") {
      // console.log("On Browser - Subsidiary");
      dispatch(getUserSubsidiaryTokenStart({ empId: teamState.employeeId as string, username: teamState.userId as string }));
    }
  }, [teamState]);

  //Api call when Subsidiary token got - UserManagerInfo API & AcrSapEmpRowCount
  React.useEffect(() => {
    if (state.userAuth.getUserSubsidiaryToken.isSuccess) {
      dispatch(getUserManagerInfoStart({ access_token: state.userAuth.getUserSubsidiaryToken?.response?.token as string, empId: teamState.employeeId as string }));
    }
    //Api call For ACR User validation - AcrSapEmpRowCount on Browser
    if (state.userAuth.getUserSubsidiaryToken.isSuccess) {
      dispatch(getAcrSapEmpRowCountStart({ compEntity: teamState.compEntity.length ? teamState.compEntity : EmpValueByCode[state.userAuth.getUserSubsidiaryToken?.response?.comp_code as keyof typeof EmpValueByCode], empId: teamState.employeeId as string }));
      //  dispatch(getAcrSapEmpRowCountStart({ compEntity: "ev", empId: teamState.employeeId as string }));

    }

    //Api call For ACR User validation - AcrSapEmpRowCount on Ms Teams
    if (state.userAuth.getUserSubsidiaryToken.isSuccess && state.userInfo.getUserInfoFromAd.isSuccess && teamState.isOnTeams === true) {
      dispatch(getAcrSapEmpRowCountStart({ compEntity: teamState.compEntity.length ? teamState.compEntity : EmpValueByCode[state.userAuth.getUserSubsidiaryToken?.response?.comp_code as keyof typeof EmpValueByCode], empId: "00" + state.userInfo.getUserInfoFromAd.response?.employeeId }));
      //      dispatch(getAcrSapEmpRowCountStart({ compEntity: "ev", empId: "00" + state.userInfo.getUserInfoFromAd.response?.employeeId }));
    }


  }, [state.userAuth.getUserSubsidiaryToken.isSuccess, state.userInfo.getUserInfoFromAd.isSuccess, teamState]);

  //Call Stock Data Api 
  React.useEffect(() => {
    dispatch(getStockDataStart());
  }, []);

  const mainComponent = () => {
    return (
      <React.Fragment>
        {
          state.acrSap.getAcrSapEmpRowCount?.isSuccess && state.userAuth.getUserSubsidiaryToken?.isSuccess ?
            (state.acrSap.getAcrSapEmpRowCount.response?.status_code === "200" && state.userAuth.getUserSubsidiaryToken?.response?.status_code === "200") ?
              <RouteList QueryData={teamState} /> :
              <Flex style={{ minHeight: "100vh" }} fill hAlign="center" vAlign="center">
                <DataNotFound message={state.userAuth.getUserSubsidiaryToken?.response?.status_code !== "200" && state.userAuth.getUserSubsidiaryToken.response?.message || "As per policy you do not have access to the system"} />
              </Flex>
            : (state.acrSap.getAcrSapEmpRowCount.isError || state.userAuth.getUserSubsidiaryToken?.isError) ?
              <ErrorComponent message={state.acrSap.getAcrSapEmpRowCount.error?.message} />
              : <LoaderComp />
        }
        <Dialog open={idleTime.isActive} content={<Flex column gap="gap.medium" fill vAlign='center' hAlign="center" >
          <Text content="Your session expired" size='larger' weight='bold' color="brand" />
          <Text content="Please click the refresh button to continue" size='large' weight='semibold' />
          {idleTime.isError && <Text size='large' content={state.userAuth.getUserSubsidiaryToken?.response?.message} error weight="bold" />}
          <Button content={idleTime.isError ? "Reload" : "Refresh"} primary loading={idleTime.isLoading} disabled={idleTime.isLoading} onClick={() => idleTime.isError ? window.location.reload() : refreshToken()} />
        </Flex>} />
      </React.Fragment>
    );
  };
  return (
    <Provider theme={teamState.activeTheme.theme} lang="en-US">
      {teamState.isOnTeams === null ? (
        <LoaderComp />
      ) : teamState.isOnTeams ? (
        <MSTeamsWrapper setTeamState={setTeamState}>
          {mainComponent()}
        </MSTeamsWrapper>
      ) : (
        mainComponent()
      )}
    </Provider>
  );
}

export default App;
