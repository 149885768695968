import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGetApprovalListFromAdPayload {
    access_token: string,
    searchQuery: string;
}

export interface IResourceObject {
    id: string;
    displayName: string;
    givenName: string;
    surname: string;
    companyName: string;
    jobTitle: string;
    department: string;
    officeLocation: string;
    additionalOfficeLocation: string;
    personType: {
        class: string;
        subclass: string;
    };
    userPrincipalName: string;
    imAddress: string;
}

export interface IHitContainerObject {
    hitId: string;
    rank: number;
    summary: string;
    resource: IResourceObject;

}

export interface IHitContainer {
    hits: IHitContainerObject[],
    total: number;
    moreResultsAvailable: boolean;
}

export interface IValueContainer {
    hitsContainers: IHitContainer[];
}

export interface IGetApprovalListFromAdData {
    value: IValueContainer[];
}

export interface IGetApprovalListFromAdDataState {
    isLoading?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    error?: any | null;
    response?: IGetApprovalListFromAdData;
}


const initialState: IGetApprovalListFromAdDataState = {};

export const getApprovalListFromAdSlice = createSlice({
    name: "getApprovalListFromAdSlice",
    initialState,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getApprovalListFromAdStart: (state, action: PayloadAction<IGetApprovalListFromAdPayload>) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        getApprovalListFromAdSuccess: (state, action: PayloadAction<IGetApprovalListFromAdData>) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
            state.response = action.payload;
        },
        getApprovalListFromAdFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { getApprovalListFromAdStart, getApprovalListFromAdSuccess, getApprovalListFromAdFailure } = getApprovalListFromAdSlice.actions;

export default getApprovalListFromAdSlice.reducer;