import React from "react";
import { app as microsoftTeams, authentication } from "@microsoft/teams-js";
import { ITeamState } from "../../App";
import { teamsTheme, teamsDarkTheme, teamsHighContrastTheme } from "@fluentui/react-northstar";
import { useDispatch } from "react-redux";
import { getUserInfoFromAdStart } from "../../redux/slice/userInfoSlice/getUserInfoFromAdSlice";
import { ErrorToast } from "../ToastifyComponent";
import { setPath } from "../../redux/slice/appSlice";
import { httpPost } from "../../services";
import { getAppInsights } from "../../telemetry/telemetryService";
import { TraceEvent } from "../../constant";


type Props = {
    setTeamState: React.Dispatch<React.SetStateAction<ITeamState>>;
    children: JSX.Element;
};


const MSTeamsWrapper = ({ setTeamState, children }: Props) => {
    microsoftTeams.initialize();
    const dispatch = useDispatch();
    const { appInsights, trackEvent, trackMetrix } = getAppInsights();

    const updateTheme = (themeStr?: string | null): void => {
        switch (themeStr) {
            case "dark":
                setTeamState((prev) => ({ ...prev, activeTheme: { type: "dark", theme: teamsDarkTheme } }));
                break;
            case "contrast":
                setTeamState((prev) => ({ ...prev, activeTheme: { type: "contrast", theme: teamsHighContrastTheme } }));
                break;
            case "default":
            default:
                setTeamState((prev) => ({ ...prev, activeTheme: { type: "default", theme: teamsTheme } }));
        }
    };

    microsoftTeams.registerOnThemeChangeHandler((theme: string) => {
        updateTheme(theme);
    });


    const getMsTeamsAccessToken = async (context: microsoftTeams.Context) => {
        const ssoURL = process.env.REACT_APP_RESOURCE_URL as string ?? "";
        try {
            const token = await authentication.getAuthToken({
                resources: [ssoURL]
            });
            trackEvent(appInsights, TraceEvent.SSO_SUCCESS, { token, context });
            trackMetrix(appInsights, { name: "ssoLogin", average: 1 }, context);
            return token;
        } catch (error) {
            trackEvent(appInsights, TraceEvent.SSO_SUCCESS_ISSUE, { error, context });
            // ErrorToast("Error during authentication to fetch ms teams token");
            throw new Error("Error during authentication to fetch ms teams token:" + error);
        }
    };

    const tokenExchange = async (token: any) => {
        return await httpPost(
            `${process.env.REACT_APP_FIN_POWER_API_URL}/sap-acr-api/azure-token-exchange`,
            { token: token }
        );
    };

    React.useEffect(() => {
        microsoftTeams
            .getContext()
            .then((context: any) => {
                // console.log("MS Teams Context", context);
                // setEmail(context?.user?.userPrincipalName);
                if (context.page.subPageId !== "") {
                    dispatch(setPath(context.page.subPageId));
                }
                updateTheme(context.app.theme);
                getMsTeamsAccessToken(context).then(
                    (token: string) => {
                        // console.log("Token-->", token);
                        tokenExchange(token).then(
                            (res: any) => {
                                setTeamState((prev) => ({ ...prev, azureAdToken: res.data.access_token }));
                                dispatch(getUserInfoFromAdStart({ access_token: res.data.access_token, upn: context?.user?.userPrincipalName as string }));
                            }
                        ).catch((err: unknown) => {
                            trackEvent(appInsights, TraceEvent.SSO_SUCCESS_ISSUE, { err, context });
                            console.error("Error while fetching token which use to call graph api ", err); ErrorToast("Error while fetching token exchange");
                        });
                    }
                ).catch((err) => {
                    trackEvent(appInsights, TraceEvent.SSO_SUCCESS_ISSUE, { err, context });
                    console.error("Error during authentication to fetch ms teams token:", err);
                });
            })
            .catch((err: unknown) => {
                trackEvent(appInsights, TraceEvent.SSO_SUCCESS_ISSUE, { err });
                console.error("Error in Context", err);
                ErrorToast("Error while fetching MS teams context");
            });
    }, []);

    return (
        <>{children}</>
    );
};

export default MSTeamsWrapper;