import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGetStockDataItem {
    id: number;
    BSE_Stock_Price: string;
    Date_Time: string;
    BSE_Change_Pct: string;
    BSE_Change_Nos: string;
    last_modify_date: string;
    createdDate: string;
    NSE_Stock_Price: string;
    NSE_Change_Pct: string;
    NSE_Change_Nos: string;
}

export interface IGetStockDataData {
    status_code: string;
    message: string;
    data: IGetStockDataItem[];
}

export interface IGetStockDataDataState {
    response: IGetStockDataData;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: any | null;
}


const initialState: IGetStockDataDataState = {
    response: {
        status_code: "",
        message: "",
        data: []
    },
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null
};

export const getStockDataSlice = createSlice({
    name: "getStockDataSlice",
    initialState,
    reducers: {
        getStockDataStart: (state) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
        },
        getStockDataSuccess: (state, action: PayloadAction<IGetStockDataData>) => {
            state.response = action.payload;
            state.isLoading = false;
            state.isSuccess = true;
            state.isError = false;
            state.error = null;
        },
        getStockDataFailure: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.error = action.payload;
        },
    },
});

export const { getStockDataStart, getStockDataSuccess, getStockDataFailure } = getStockDataSlice.actions;

export default getStockDataSlice.reducer;