import { put, takeLatest } from "redux-saga/effects";
import { httpPost } from "../../../services";
import { getAcrSapApprovalListStart, getAcrSapApprovalListSuccess, getAcrSapApprovalListFailure, IGetAcrSapApprovalListPayload } from "../../slice/acrSapSlice/getAcrSapApprovalListSlice";
import { FIN_POWER_BASE_URL, ACR_SAP_CONFIG } from "../../../config";

function* GetAcrSapEmpApprovalListSaga(actions: { type: string, payload: IGetAcrSapApprovalListPayload; }) {
    try {
        const { data } = yield httpPost(FIN_POWER_BASE_URL + ACR_SAP_CONFIG.GET_ACR_SAP_APPROVAL_LIST,
            actions.payload,
            {
                headers: {
                    // token: API_SECRET_TOKEN,
                    // "x-teams-origin": window.location.origin
                    // "x-teams-origin": "http://localhost:3000"
                }
            });

        yield put(getAcrSapApprovalListSuccess(data));

    } catch (error) {
        console.error("Error fetching GetAcrSapEmpRowCountSaga:", error);
        yield put(getAcrSapApprovalListFailure(error));
    }
}

export default function* watchGetAcrSapApprovalListSaga() {
    yield takeLatest(getAcrSapApprovalListStart, GetAcrSapEmpApprovalListSaga);
    // while (true) {
    //     const { payload } = yield take(getAcrSapApprovalListStart.type);
    //     yield call(GetAcrSapEmpApprovalListSaga, payload);
    // }
}